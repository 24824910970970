import * as styles from './TeamCard2.module.css';

import EmailIcon from '../../images/email.svg';
import { Link } from 'gatsby';
import LinkedInIcon from '../../images/linkedin.svg';
import PhoneIcon from '../../images/phone.svg';
import React from 'react';
import slugify from 'slugify';

const TeamCard2 = (props) => {
  const { Name, LinkedIn, Email, PhoneNo, Picture } = props.data;
  return (
    <div className={styles.TeamCard}>
      <div className={styles.Image}>
        <img src={Picture.url} alt={Name} />
      </div>
      <div className={styles.TeamInfo}>
        <Link to={`/about/team/${slugify(Name, { lower: true })}`}>
          <h6 className={styles.Name}>{Name}</h6>
        </Link>
        <div className={styles.BulletPoints}>
          <ul>
            <li>
              <EmailIcon />
              <a href={`mailto:${Email}`}>{Email}</a>
            </li>
            <li>
              <PhoneIcon />
              <a href={`tel:${PhoneNo}`}>{PhoneNo}</a>
            </li>

            <li>
              <LinkedInIcon />
              <a href={`${LinkedIn}`}>
                linkedin.com/{slugify(Name, { lower: true })}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TeamCard2;
