// import Banner from '../components/Banner/Banner';
import BlockRow from '../components/UI/BlockRow/BlockRow';
// import Card from '../components/Card/Card';
import Image from '../components/UI/Image/Image';
import Layout from '../containers/layout';
// import PageHeader2 from '../components/PageHeader/PageHeader2';
import React, {useState, useEffect} from 'react';
import TeamBio from '../components/UI/TeamInfo/TeamBio';
import ProductInfo from '../components/UI/TeamInfo/ProductInfo';
import GradientLoader from '../components/Loaders/GradientLoader';
import MFRTeam from '../components/MFRTeam/MFRTeam';
// import { navigate } from 'gatsby';
// import Video from '../components/UI/Video/Video';
// import { getUrl } from '../util';
// import { graphql } from 'gatsby';

function filterAndJoinStrings(array, separator) {
  const filteredArray = array.filter((item) => {
    // Filter out falsy values, empty strings, and 'null'
    return item !== null && item !== undefined && item.toString() !== '' && item.toString() !== 'null';
  });

  let result;
  if (separator === '|') {
    result = filteredArray.join(' | ');
  } else if (separator === ',') {
    result = filteredArray.join(', ');
  } else {
    result = filteredArray.join(' '); // Default separator is space
  }

  return result;
}


const ProductPage = ({location, pageContext}) => {

  const [productData, setProductData] = useState({foundProduct:true});

  const getData = async () => {
    const response = await fetch("https://ixzg4exzue.execute-api.us-east-1.amazonaws.com/Prod/"+location.href.split("/").pop());
    const data =  await response.json();

    if(data.body.length>20){
      setProductData({...productData, ...JSON.parse(data.body).Item, mainImage: 'https://d1mx6xtulfr7us.cloudfront.net/coming-soon.png'});
    }else{
      setProductData({foundProduct:false});
    }
  };

  useEffect(() => {
    if(!productData.ITEMDESC && productData.foundProduct===true){
      getData();
    }
  });

  const getExtension = url => (url = url.substr(1 + url.lastIndexOf("/")).split('?')[0]).split('#')[0].substr(url.lastIndexOf("."));

  const manufacturer = pageContext.data.cat?pageContext.data.cat.toLowerCase().includes("cisco") ? "Cisco" : pageContext.data.cat.toLowerCase().includes("dell") ? "Dell" : pageContext.data.cat.toLowerCase().includes("hpe") ? "HPE" : "" : "";
  const sku = pageContext.data.sku?pageContext.data.sku:"";
  const productName = pageContext.data.desc;
  const stk_group1 = pageContext.data.sg1;
  const stk_group2 = pageContext.data.sg2;
  const web_group1 = pageContext.data.wg1;
  const alt_part_num = pageContext.data.alt;

  
  const seoData = {
    MetaTitle: `${manufacturer} | ${sku}`,
    MetaDescription: filterAndJoinStrings([ productName , sku , alt_part_num , manufacturer , web_group1 , stk_group1 , stk_group2 ], '|').substring(0,150),
    Keywords: `V3 Distribution, shop, products, `+ filterAndJoinStrings([ sku , alt_part_num, manufacturer, web_group1, stk_group1, stk_group2, productName ], ','),
    PreviewImage: {url:`https://d1mx6xtulfr7us.cloudfront.net/${pageContext.data.sku}/0_original_large`}
  };

  // window.seodata=seoData;
  // window.productdata=productData;
  
  //await new Promise(resolve => setTimeout(resolve, 100));
  
  return (
    <Layout seoData={seoData} location={location}>
      <main>
        {productData.foundProduct===true?(
        <BlockRow mobileReverse noPadding>
            
            {!productData.images && !pageContext.data.desc?(
              <GradientLoader height="350px" width="400px" />
            ):(pageContext.customImage?
              <Image alt={`${manufacturer} | ${pageContext.data.sku}`} img={`https://d1mx6xtulfr7us.cloudfront.net/0-custom-image/images/${pageContext.customImage}`} />
            :(
              <Image alt={`${manufacturer} | ${pageContext.data.sku}`} img={productData.images && !productData.images.includes('no-image')?`https://d1mx6xtulfr7us.cloudfront.net/${encodeURIComponent(pageContext.data.sku.replace('/','%2F'))}/0_original_large${getExtension(productData.images.split(',')[0])}`:"https://d1mx6xtulfr7us.cloudfront.net/coming-soon.png"} />
            ))}

            {!pageContext.data.desc?(
              <div style={{width:"100%"}} >
                <GradientLoader height="25px" width="100%" /><br />
                <GradientLoader height="25px" width="100%" /><br />
                <GradientLoader height="25px" width="100%" /><br />
                <GradientLoader height="25px" width="100%" /><br />
              </div>
            ):(
              <ProductInfo
                name={pageContext.data.desc?pageContext.data.desc=='null'?'':pageContext.data.desc:"waiting"}
                sku={pageContext.data.sku?pageContext.data.sku:"waiting"}
                price={(i=>typeof i==='string'&&i.length>0&&![NaN,undefined,0].includes(parseInt(i)))(productData.Price)?(new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',})).format(productData.Price):''}
              />
            )}

        </BlockRow>
        ):<BlockRow mobileReverse noPadding><h3>Product Not Found</h3></BlockRow>}
        <TeamBio
          background={'gray70'}
          productinfo={{
            Manufacturer:pageContext.data.cat?pageContext.data.cat.toLowerCase().includes("cisco")?"Cisco":pageContext.data.cat.toLowerCase().includes("dell")?"Dell":pageContext.data.cat.toLowerCase().includes("hpe")?"HPE":pageContext.data.cat:"",
            Categories:[pageContext.data.wg1,pageContext.data.sg1,pageContext.data.sg2].filter(item=>item && item!="" && item!="null" && item!="<empty>" && item!="test").map(item=><>{item}<br /></>),
            SKU:pageContext.data.sku?pageContext.data.sku:"",
            Alternate_Part_Number:[ 
              (i=>typeof i==='string'&&i.length>0&&i!=='null'&&i!='<empty>')(pageContext.data.alt)?pageContext.data.alt:false,
              (i=>typeof i==='string'&&i.length>0&&i!=='null'&&i!='<empty>')(productData.gtin)?productData.gtin:false,
              (i=>typeof i==='string'&&i.length>0&&i!=='null'&&i!='<empty>'&&i!==productData.gtin)(productData.upc)?productData.upc:false
            ].filter(i=>i).map(item=><>{item}<br /></>)
          }}
          longdescription="Looking for something different? Let us know how we can help! **Contact Us**"

        />
        {pageContext.data.cat?(
          <MFRTeam team={pageContext.data.cat.toLowerCase().includes("cisco")?"Cisco":pageContext.data.cat.toLowerCase().includes("dell")?"Dell":pageContext.data.cat.toLowerCase().includes("hpe")?"HPE":""} />
        ):null}
        </main>
    </Layout>
  );
};



export default ProductPage;