import React from 'react';
import * as styles from './Marquee.module.css'; 

import { graphql, useStaticQuery } from 'gatsby';

import TeamCard2 from '../Card/TeamCard2';


function Marquee(props) {


  const data = props.data;

  const team = props.team;
  const members = data.allStrapiTeamMembers.nodes.filter(
    (el) => el.Team === team
  );

  return (
    <section className={styles.enableanimation}>
      <div className={styles.marquee}>
        <ul className={styles['marquee__content']}>
        {members.map((el, i) => {
          return <li><TeamCard2 data={el} key={i} /></li>;
        })}

        </ul>
        <ul aria-hidden="true" className={styles['marquee__content']}>
        {members.map((el, i) => {
          return <li><TeamCard2 data={el} key={i} /></li>;
        })}

        </ul>
      </div>
    </section>
  );
}

export default Marquee;