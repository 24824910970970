import * as styles from './MFRTeam.module.css';

import { graphql, useStaticQuery } from 'gatsby';

import React from 'react';
import Marquee from '../Marquee/Marquee';

const MFRTeam = (props) => {
  const data = useStaticQuery(graphql`
    query mfrTeamQuery {
      allStrapiTeamMembers(filter: { Team: { in: ["Dell", "HPE", "Cisco"] } }) {
        nodes {
          Team
          LinkedIn
          Name
          Email
          PhoneNo
          MeetingLink
          Picture {
            url
          }
        }
      }
    }
  `);

  const team = props.team;
  const members = data.allStrapiTeamMembers.nodes.filter(
    (el) => el.Team === team
  );

  return (
    <div className={styles.MFRTeam}>
      <h3>Meet the Team</h3>
      <Marquee team={team} data={data} />
    </div>
  );
};
export default MFRTeam;
