import * as styles from './CTAbutton.module.css';

import React from 'react';

const isExternalLink = (url) => {
  if(typeof url === 'string'){
    return (url.indexOf("http://") == 0 || url.indexOf("https://") == 0)?true:false;
  }
};

const CTAbutton = (props) => {

  if(typeof props.url === 'string' && props.url.split(':')[0]=="javascript"){
    return(
      <div
        style={props.style}
        onClick={props.handler}
        js-name={props.url}
        className={styles.CTAbutton+(!!props.classNames?' '+props.classNames.map(elem=>styles[elem]).join(' '):'')}
      >
        {props.message}
      </div>
    )
  }else{
    return (
      <a
        href={props.url}
        style={props.style}
        target={(isExternalLink(props.url)?'_blank':'')}
        className={styles.CTAbutton+(!!props.classNames?' '+props.classNames.map(elem=>styles[elem]).join(' '):'')}
      >
        {props.message}
      </a>
    );
  }
};

export default CTAbutton;