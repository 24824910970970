import * as styles from './TeamInfo.module.css';
import React, { useState } from 'react';
import CTAbutton from '../../CTAbutton/CTAbutton';
import PopupQuote from '../../../components/Forms/PopupQuote';


const Block = (props) => {

  const [ showForm, setShowForm ] = useState( false );

  const toggleShowForm = e => {
    setShowForm( !showForm );
  }
  
  return (
    <section className={styles.TeamInfo}>
      {showForm?<PopupQuote sku={props.sku} closehandler={ () => toggleShowForm() } />:null}
      <h1>{props.name}</h1>
      <h4>{props.sku}</h4>
      <br />
      <br />
      <h2>{props.price}</h2>
      <CTAbutton handler={(e)=>toggleShowForm(e)} style={{width:'200px',cursor:'pointer'}} url="javascript" message="REQUEST A QUOTE" />
    </section>
  );
};

export default Block;